<template>
  <div>
    <a-page-header style="padding: 0;" title="Annual Leaves">
      <template #extra>
      </template>
    </a-page-header>


    <div class="mt-20">
      <a-table :dataSource="list" :columns="columns" :loading="loading" :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: currentPage,
        defaultPageSize: limit,
      }" @change="tableChange">
        <template #remainder="{ record: item }">
          {{ item.total - item.used }}
        </template>
        <template #update="{ text }">
          {{ $filters.formatTime(text) }}
        </template>
        <template #employmentDays="{ text }">
          {{ $filters.formatTimeByDay(text) }}
        </template>
        <template #deduct="{ text }"> {{ text }} </template>
        <template #tool="{ text }">
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link" @click.prevent>
              <SettingOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="showUpdateModal(text)">
                  Update
                </a-menu-item>
                <a-menu-item @click="showDeleteConfirm(text)">
                  Delete
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, watch, onMounted, createVNode, inject } from "vue";
import { useRoute } from "vue-router";
import {
  SettingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

const columns = [
  {
    dataIndex: "userId",
    ellipsis: true,
    title: "User Id",
  },
  {
    title: "Name",
    ellipsis: true,
    dataIndex: "userName",

  },
  {
    title: "Total",
    ellipsis: true,
    dataIndex: "total",
  },
  {
    title: "Used",
    ellipsis: true,
    dataIndex: "used",
  },
  {
    title: "Remainder",
    ellipsis: true,
    slots: { customRender: "remainder" },
  },
  {
    title: "Employment Time",
    ellipsis: true,
    dataIndex: "employmentDays",
    slots: { customRender: "employmentDays" },
  },
  {
    title: "Last Calculated Date",
    ellipsis: true,
    dataIndex: "lastCalculatedDate"
  },
  {
    title: "Last Used Date",
    ellipsis: true,
    dataIndex: "lastUsedDate"
  },


  // {
  //   title: "...",
  //   dataIndex: "key",
  //   key: "tool",
  //   slots: { customRender: "tool" },
  // },
];
export default {
  components: {
    SettingOutlined,
    ExclamationCircleOutlined,
  },
  setup() {
    const { $axios, $msg } = inject('$')
    const v = reactive({
      list: [],
      listloading: false,
      loading: false,
      total: 0,
      page: 1,
      limit: 10,
      currentPage: 1,

    });

    async function getList() {
      v.listloading = true;
      try {
        const res = await $axios.get("/api/v1/users/annual_leaves");
        v.listloading = false;
        if (res.data) {
          v.list = res.data.items;
          v.total = res.data.total
          v.list.forEach((item, index) => {
            item.key = index;
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        v.listloading = false;
      }
    }
    getList();

    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
      getlist();
    }
    return {
      ...toRefs(v),
      tableChange,
      columns,

    };
  },
};
</script>
<style lang="scss" scoped></style>
  